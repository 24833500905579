import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <main className="p-10 mx-auto space-y-4 max-w-screen-xl">
      <h1 className="text-2xl font-bold">
        <strong>Privacy Policy</strong>
      </h1>

      <p>
        At Mickle Senior Benefits, we believe your privacy and the security of
        your information are important. The following Privacy Policy details our
        information gathering, protection, and handpng practices relating to use
        of the website micklesenior.com. If you have questions or concerns about
        our privacy policy, you can contact us by phone at 844-565-1957.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Why We Collect Visitor Information</strong>
      </h2>

      <p>
        (1) To enable interactions between you and us, such as applications for,
        and purchases of our products; to support your interactions with us; to
        provide information about insurance plans; to communicate with you about
        your policy or our data practices; to install and configure changes and
        updates to programs and technologies related to interactions with us; to
        authenticate those who interact with us; and to respond to your
        requests, complaints, and inquiries.
      </p>
      <p>
        (2) For our own internal business purposes, such as to evaluate or audit
        the usage and performance of programs and technologies related to
        interactions with us; to evaluate and improve the quality of your
        interactions with us and the quality of programs and technologies
        related to interactions with us; to design new services and products; to
        process and catalog your responses to surveys or questionnaires (e.g.,
        customer satisfaction reviews); to perform internal research for
        technological development and demonstration; to conduct data analysis
        and testing; and to maintain proper business records.
      </p>
      <p>
        (3) For legal, safety, or security reasons, such as to comply with laws
        and other legal requirements; to protect our safety, our property or the
        rights of those who interact with us, or others; and to detect, prevent,
        and respond to security incidents or other malicious, deceptive,
        fraudulent, or illegal activity.
      </p>
      <p>
        (4) In a de-identified, anonymized, or aggregated format. We may convert
        Personal Information into a de-identified, anonymized, or aggregated
        format, and use such information for any legal purpose.
      </p>
      <p>
        (5) For marketing. We may use your Personal Information to market our
        products or services or those of third parties, such as our business
        partners. We may use your Personal Information to invite you to
        participate in marketing surveys, questionnaires, promotions, events or
        contests. We may audit aspects of our ad impressions. We may use your
        Personal Information for short-term transient use, including for
        contextual ad customization. We may use your Personal Information to
        enhance our content and to deliver advertisements to you, including in
        commercial emails.
      </p>
      <p>
        (6) To fulfill other purposes disclosed at the time you provide Personal
        Information or otherwise where we are legally permitted or are required
        to do so.
      </p>
      <p>(7) For any other purposes for which you provide consent.</p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Information Collected & How We Use It</strong>
      </h2>

      <h3 className="text-l font-semibold mt-2">Insurance Request Form</h3>

      <p>
        By filling out this form, you are requesting contact by a member of our
        staff regarding your purchase of insurance. The information collected on
        this form (name, mailing address, email, and phone numbers) will be used
        only to contact you regarding your purchase of insurance. Mickle Senior
        Benefits does not sell or give away this information. Mickle Senior
        Benefits may share this information with insurance carriers in an effort
        to provide the best service and products to you, our client.
      </p>
      <h3 className="text-l font-semibold mt-2">
        Cookie and Automatic Data Collection Tools
      </h3>

      <p>
        We collect information, via cookies, web beacons, pixels, tags, or other
        tracking technologies, such as your Internet Service Provider and IP
        address, the date and time you access our website, the pages you access
        while visiting our website, and the Internet address from which you
        access our website. Some cookies exist only during a single session and
        some are persistent over multiple sessions over time. Our website may
        use cookies to help identify your computer and keep track of your
        preferences so that we can tailor your user experience. We may also use
        them for promotional campaigns, tracking our audience size and traffic
        patterns, re-targeting and in certain other cases. Third party
        advertisers may also create and access cookies, which will be subject to
        their privacy policies – we accept no liability for the use of such
        third parties’ cookies. If you do not wish cookies to be placed in your
        PC or mobile device, then they can be disabled by you in your web
        browser’s security settings.
      </p>
      <h3 className="text-l font-semibold mt-2">Log Files</h3>

      <p>
        When visitors enter and navigate through the pages of our site, our
        server logs information, including the internet address of the server or
        computer contacting us, the date and time, the referring site and its
        URL, as well as the visitor’s browser, operating system, and screen
        resolution. This data is analyzed to understand better what our visitors
        are looking for and to improve the quality of our site. We look for
        trends, identify most- and least-visited pages, and collect broad
        demographic information. None of the information gathered through our
        log files is shared with or sold to outside organizations. Nor do we
        look at individual users and their usage patterns, or use these
        technologies to gather emails or personally identifiable information.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Communication with Clients</strong>
      </h2>

      <p>
        Once a user has initiated a relationship with Mickle Senior Benefits
        through this site, various forms of communication may ensue. They
        include
      </p>
      <p>(1) contact by telephone</p>
      <p>(2) contact by email;</p>
      <p>(3) contact by mail</p>
      <p>(4) contact by fax.</p>
      <p>
        All contact is to help our clients apply for, purchase, and/or get
        insurance. Contact will only come from a Mickle Senior Benefits agent
        and/or affiliated staff.
      </p>
      <p>
        If at any time you desire that we cease contact with you, please reply
        by e-mail, telephone or mail and we will immediately remove you from our
        database.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Your Options</strong>
      </h2>

      <h3 className="text-l font-semibold mt-2">On MickleSenior.com</h3>

      <p>
        This site is open for public use without providing any personally
        identifiable information.
      </p>
      <p>
        By providing your personally identifiable information on this site, you
        are explicitly initiating a relationship (as defined by the National Do
        Not Call Registry) with Mickle Senior Benefits and are inviting,
        consenting to, and requesting contact and information from us, which may
        be provided to you online, by phone, fax, email, or mail by Mickle
        Senior Benefits or one of our carriers, affiliates, vendors, or
        partners. As such, you are waiving any right (including claims under the
        Telemarketing Sales Rule) extending in perpetuity to claim that we
        contacted you without your express consent.
      </p>
      <p>
        If, at any time, you desire that we cease contact with you, we will
        honor your request to place you on our internal “Do Not Contact” list.
      </p>
      <h3 className="text-l font-semibold mt-2">Email</h3>

      <p>
        You can unsubscribe from our email mailing list at any time by clicking
        on the “unsubscribe” pnk at the bottom of any one of our emails. Or
        simply reply with the message “unsubscribe”.
      </p>
      <h3 className="text-l font-semibold mt-2">Cookies</h3>

      <p>
        You may disable cookies on your browser at any time by following the
        directions provided to you by your browser.
      </p>
      <h3 className="text-l font-semibold mt-2">
        Update/Change Personal Information
      </h3>

      <p>
        If you would like to update or change the personal information we have
        on file for you, please contact us at 844-565-1957
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>
          Carrier, Partner, Vendor, and Affiliate Privacy Policies
        </strong>
      </h2>

      <p>
        Mickle Senior Benefits may share your personally identifiable
        information with insurance carriers or other partners, vendors, or
        affiliates for the express purpose of assisting you in your purchase of
        insurance. Mickle Senior Benefits is not liable or responsible for these
        third-party companies’ privacy policies or procedures, or any loss or
        breach of data through these third-party systems. Please contact these
        individual organizations with questions regarding their Privacy
        Policies.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Spam Policy</strong>
      </h2>

      <p>Mickle Senior Benefits does not spam.</p>
      <p>
        Spam or UCE (Unsolicited Commercial Email) is an attempt to deliver an
        email to someone who would not choose to receive it. If you receive an
        email from Mickle Senior Benefits, it is because (1) you expressly
        shared your email address with us for the purpose of receiving
        information in the future (also known as “opt-in”), or (2) you have
        purchased from us or have a current relationship with us.
      </p>
      <p>
        By requesting information from us or providing your contact information
        on our site, you have agreed to opt-in to receive our information.
      </p>
      <p>
        You can unsubscribe from our email mailing list at any time by clicking
        on the “unsubscribe” link at the bottom of any one of our emails or by
        replying to the email with the subject pne, “unsubscribe”.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Privacy Policy Updates</strong>
      </h2>

      <p>
        This Privacy Policy may be updated at any time. Mickle Senior Benefits
        is always looking for new and safer ways to protect our clients’
        information. Consequently, this Privacy Policy may be updated to reflect
        those changes. It is suggested that you review this Privacy Policy each
        time you use this site.
      </p>
      <h2 className="text-xl font-semibold mt-2">
        <strong>Contact Us</strong>
      </h2>

      <p>
        If you have any questions regarding this document, our procedures and
        policies, this site, or your relationship with us, please contact us at
        the address below.
      </p>
      <address>
        <p>Mickle Senior Benefits</p>
        <p>11816 Inwood Rd #3050</p>
        <p>Dallas, TX 75244</p>
      </address>
    </main>
  </Layout>
)

export default PrivacyPage
